<template>
  <v-card width="95%" style="margin: 15px auto;" v-if="cardLoaded">
    <v-card-title class="primary white--text">
      <v-spacer></v-spacer>
      Отчет по карте: {{card.card.name}}
      <v-spacer></v-spacer>
      <v-btn color="info" to="/"><v-icon>mdi-magnify</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <div class="title text-center pt-4">Идентификаторы</div>
      <v-simple-table dense class="table-last-column-right">
        <tbody>
          <tr>
            <td>Идентификатор карты (ID карты)</td>
            <td>{{card.cardId}}</td>
          </tr>
          <tr>
            <td>Порядковый номер шва по техпроцессу</td>
            <td>{{card.queue}}</td>
          </tr>
          <tr>
            <td>Номер (шифр) стыка</td>
            <td>{{card.code}}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Характеристика применимости</div>
      <v-simple-table dense class="table-last-column-right">
        <tbody>
          <tr>
            <td>Способ сварки</td>
            <td>{{ card.card.weldMethod }}</td>
          </tr>
          <tr>
            <td>Вид соединяемых элементов</td>
            <td>{{ card.card.elementsType }}</td>
          </tr>
          <tr>
            <td>Тип шва</td>
            <td>{{ card.seam }}</td>
          </tr>
          <tr>
            <td>Тип соединения</td>
            <td>{{ card.card.jointType }}</td>
          </tr>
          <tr>
            <td>Материал элементов</td>
            <td>{{ card.card.baseMaterial }}</td>
          </tr>
          <tr v-if="card.card.d1">
            <td>Диаметр элемента 1, мм (D1)</td>
            <td>{{ card.card.d1 }}</td>
          </tr>
          <tr v-if="card.card.d2">
            <td>Диаметр элемента 2, мм (D2);</td>
            <td>{{ card.card.d2 }}</td>
          </tr>
          <tr v-if="card.card.s1">
            <td>Толщина элемента 1, мм (S1)</td>
            <td>{{ card.card.s1 }}</td>
          </tr>
          <tr v-if="card.card.s2">
            <td>Толщина элемента 2, мм (S2)</td>
            <td>{{ card.card.s2 }}</td>
          </tr>
          <tr>
            <td>Сварочный материал</td>
            <td>{{ card.card.weldMaterial }}</td>
          </tr>
          <tr>
            <td>Положение в пространстве</td>
            <td>{{ card.card.weldPosition }}</td>
          </tr>
          <tr>
            <td>Прочие параметры</td>
            <td>{{ card.card.othes }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Данные о реализации</div>
      <v-simple-table dense class="table-last-column-right">
        <tbody>
          <tr v-if="welderLoaded">
            <td>Ф.И.О сварщика</td>
            <td>{{ welder.lastname + " " + welder.firstname + " " + welder.middlename }}</td>
          </tr>
          <tr v-if="welderLoaded">
            <td>Клеймо сварщика</td>
            <td>{{ welder.welderKey }}</td>
          </tr>
          <tr>
            <td>Фактическое количество слоёв</td>
            <td> {{card.layers.length}}</td>
          </tr>
          <tr>
            <td>Дата начала</td>
            <td>{{secToDate(card.startedAt)}}</td>
          </tr>
          <tr>
            <td>Время начала</td>
            <td>{{secToTime(card.startedAt)}}</td>
          </tr>
          <tr>
            <td>Дата завершения</td>
            <td>{{secToDate(card.finishedAt)}}</td>
          </tr>
          <tr>
            <td>Время завершения</td>
            <td>{{secToTime(card.finishedAt)}}</td>
          </tr>
          <tr>
            <td>Общее время выполнения</td>
            <td>{{secToHoursTime(card.finishedAt - card.startedAt) }}</td>
          </tr>
          <tr>
            <td>Время сварки</td>
            <td>{{secToHoursTime(card.weldTime / 1000)}}</td>
          </tr>
          <tr>
            <td>Время дополнительных операций</td>
            <td>
              {{
                secToHoursTime((card.finishedAt - card.startedAt) - 
                  (card.weldTime / 1000))
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="title text-center pt-4">Суммарные отклонения</div>
      <v-treeview 
        class="bottom-border"
        :items="cardDevs" 
        :open-on-click="true" 
        >
      </v-treeview>
      <div class="title text-center pt-4">Поканальные отклонения</div>
      <template v-for="chDevs in channelsDevs">
        <div 
          class="my-4 px-2 bottom-border"
          :key="chDevs[0].channelId + 'main' + chDevs[0].id" 
          >
          <div class="subheader text-center">
            {{getChannelNameById(chDevs[0].channelId)}}
          </div>
          <v-treeview 
            :items="chDevs" 
            :open-on-click="true" 
            >
          </v-treeview>
        </div>
      </template>
      <div class="title text-center pt-4">
        Индикаторные отклонения для слоя №{{indiLayerIndx + 1}}
      </div>
      <v-simple-table class="text-center">
        <thead>
          <tr>
            <td>доп</td>
            <td>крит</td>
            <td>общ</td>
          </tr>
        </thead>
        <tbody>
            <td>
            {{toPercentString(
              card.layers[indiLayerIndx].devAllow, 
              card.layers[indiLayerIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              card.layers[indiLayerIndx].devCrit, 
              card.layers[indiLayerIndx].weldTime
            )}}</td>
            <td>
            {{toPercentString(
              card.layers[indiLayerIndx].devCommon, 
              card.layers[indiLayerIndx].weldTime
            )}}</td>
        </tbody>
      </v-simple-table>      
      <div class="title text-center">По каналам</div>
      <template v-for="chDevs in channelsIndicators">
        <div 
          class="my-4 px-2 bottom-border"
          :key="chDevs[0].channelId + 'indi' + chDevs[0].id" 
          >
          <div class="subheader text-center">{{getChannelNameById(chDevs[0].channelId)}}</div>
          <v-treeview 
            :items="chDevs" 
            :open-on-click="true" 
            >
          </v-treeview>
        </div>
      </template>
      <div class="title text-center">Слои тех. карты</div>
      <v-simple-table class="table-text-center" dense>
        <thead>
          <tr>
            <th>№ слоя</th>
            <th>Тип слоя</th>
            <th>Начат</th>
            <th>Закончен</th>
            <th>Время выполнения</th>
            <th>Время сварки</th>
            <th>Iср</th>
            <th>Uср</th>
            <th>Доп. откл.</th>
            <th>Крит. откл.</th>
            <th>Общ. откл.</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="layer in card.layers"
            @click="goToViewProcCardLayer(layer.id)"" 
            style="cursor: pointer;"
            >
            <td>{{layer.num}}</td>
            <td>{{getLayerNameById(layer.layerNameId)}}</td>
            <td>
              <div>{{secToDate(layer.startedAt)}}</div>
              <div>{{secToTime(layer.startedAt)}}</div>
            </td>
            <td>
              <div>{{secToDate(layer.finishedAt)}}</div>
              <div>{{secToTime(layer.finishedAt)}}</div>
            </td>
            <td>{{secToHoursTime(layer.finishedAt - layer.startedAt)}}</td>
            <td>{{secToHoursTime(layer.weldTime/1000)}}</td>
            <td>{{getAverage(layer.channelsAverages, 1)}}</td>
            <td>{{getAverage(layer.channelsAverages, 2)}}</td>
            <td>{{toPercentString(layer.devAllow, layer.weldTime)}}</td>
            <td>{{toPercentString(layer.devCrit, layer.weldTime)}}</td>
            <td>{{toPercentString(layer.devCommon, layer.weldTime)}}</td>            
          </tr>
        </tbody>
      </v-simple-table>      
    </v-card-text>
  </v-card>
</template>


<script>
import { secToDate } from '@/libs/time.js'
import { secToTime } from '@/libs/time.js'
import { secToHoursTime } from '@/libs/time.js'
import { prepareDevs } from '../../libs/helpers.js'
import { getChannelNameById } from '@/libs/common.js'
import { getLayerNameById } from '@/libs/common.js'
import { toPercentString } from '@/libs/common.js'
import { mapState } from 'vuex'
export default {
  data: () => ({
    secToTime,
    secToDate,
    secToHoursTime,
    prepareDevs,
    getChannelNameById,
    getLayerNameById,
    toPercentString,        
    cardLoaded: false,
    card: {},
    cardDevs: [],
    channelsDevs: [],    
    welderLoaded: false,
    welder: {},
    indiLayerIndx: 0
  }),
  computed: {
    ...mapState('common', { 
      modules: state => state.modules ,
      channelsNamesLength: state => state.channelsNames.length 
    }),
    channelsIndicators() {
      let lCommon = 0, res =[]
      this.card.layers.forEach((l, i) => {
        if ((Number(l.devCommon) / Number(l.weldTime)) > lCommon) {
          this.indiLayerIndx = i
          lCommon = Number(l.devCommon) / Number(l.weldTime)
        }
      })
      this.card.layers[this.indiLayerIndx].channelsDeviations.forEach(chDevs => {
        res.push([Object.assign(
          {}, 
          this.prepareDevs(chDevs, this.card.layers[this.indiLayerIndx].weldTime), 
          { channelId: chDevs.channelId }
        )])
      })      
      return res
    }
  },   
  methods: {
    goToViewProcCardLayer(id) {
      this.$router.push('/manufacture/process-card-layer/view?id=' + id)
    },
    getAverage(avrs, channelId) {
      let chAvr = avrs.find(a => Number(channelId) === Number(a.channelId))
      if (chAvr) {
        return (Number(chAvr.integrator) / Number(chAvr.samplesCount)).toFixed(1) 
      }
      return 'Нет'
    }
  }, 
  mounted: function() {
    let url = '/manufacture/process-card/view?id=' + this.$route.query.id
    this.$axios.get(url).then(res => {
      this.card =  res.data
      this.cardDevs.push(this.prepareDevs(this.card, this.card.weldTime))
      this.card.channelsDeviations.forEach(chDevs => {
        this.channelsDevs.push([Object.assign(
          {}, 
          this.prepareDevs(chDevs, this.card.weldTime), 
          { channelId: chDevs.channelId }
        )])
      })
      this.$axios.get('/user/profile/welder-info', { params: { id: this.card.welderId }})
      .then(wres => {
        this.welder = wres.data
        this.welderLoaded = true
      }).catch(() => {})
      this.cardLoaded = true
    }).catch(() => {})
  },
}
</script>

<style>
  .table-last-column-right tbody tr td:last-child {
    text-align: right;
  }
  .table-text-center {
    text-align: center;
  }
  .table-text-center th {
    text-align: center !important;
  }
  .bottom-border {
    border-bottom-style: solid;
    border-bottom-width: thin;    
  }
</style>